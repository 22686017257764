import request from "@/request";
import { Article, Detail, InfoDetailModel, InfoListModel, Page, SpecialListModel, TagList } from "@/api/college/model";

export class College {
  //专题
  static SpecialList() {
    return request.get<SpecialListModel[]>('/api6/ignored/operation/h5news/topicPage')
  }

  //热门标签
  static HotTags() {
    return request.get<TagList[]>('/api6/ignored/operation/h5news/hotTags/')
  }

  //最新资讯
  static NewestInfo(params: Page) {
    return request.get<InfoListModel[]>('/api6/ignored/operation/h5news/page', params)
  }

  //最新资讯（标签、专题）
  static NewestInfoEntry(params: Page) {
    return request.get<InfoListModel[]>('/api6/ignored/operation/h5news/entryNewsPage', params)
  }
  //资讯详情
  static InfoDetail(data: InfoDetailModel) {
    return request.post<Detail>('/api6/ignored/operation/h5news/detail', data)
  }

  //推荐资讯
  static recommend(params: Page) {
    return request.get<Article[]>('/api6/ignored/operation/h5news/recommendNews', params)
  }
}
