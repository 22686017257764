import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import MetaInfo from "vue-meta-info";
import "normalize.css";
import "./utils/flexible.js";
import "animate.css";
import "@/assets/css/main.less";
import "@/assets/css/font.less";
import VueClickAway from "vue3-click-away";
import "element-plus/theme-chalk/display.css";
import "element-plus/theme-chalk/el-message.css";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import "vant/lib/notify/style";
bootstrap();

function bootstrap() {
  const app = createApp(App);

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

  app.use(router).use(MetaInfo).use(VueClickAway);
  app.mount("#app");
}
