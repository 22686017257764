import request from "@/request";

export class Api {
  static getAuthCode(params: any) {
    return request.get("/api-cloud/admin/website/phone/send", params);
  }
  static submit(params: any) {
    return request.post("/api-cloud/admin/website/phone", params);
  }
}
