import { defineComponent, ref } from "vue";
import RecommendArticle from "@/views/college/components/RecommendArticle.vue";
import Tag from "@/views/college/components/Tag.vue";
import TopicDetail from "@/views/college/components/TopicDetail.vue";
export default defineComponent({
  name: 'zixunDetail',
  components: {
    RecommendArticle,
    Tag,
    TopicDetail
  },
  metaInfo() {
    return {
      title: '天玑云-' + ((this.info?.browserTitle ?? '') !== '' ? this.info?.browserTitle : this.info?.title),
      meta: [{
        name: "description",
        content: (this.info?.browserDescription ?? '') !== '' ? this.info?.browserDescription : this.info?.summary || this.info?.title
      }, {
        name: "keywords",
        content: (this.info?.browserTitle ?? '') !== '' ? this.info?.browserTitle : "天玑云" + this.info?.title
      }]
    };
  },
  setup: () => {
    const info = ref({});
    const getInfo = val => {
      info.value = val;
      // document.dispatchEvent(new Event("render-event"));
    };
    return {
      info,
      getInfo
    };
  }
});