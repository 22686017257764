import { defineComponent } from "vue";
import RecommendArticle from "@/views/college/components/RecommendArticle.vue";
import NewestArticle from "@/views/college/components/NewestArticle.vue";
import Tag from "@/views/college/components/Tag.vue";
export default defineComponent({
  components: {
    RecommendArticle,
    NewestArticle,
    Tag
  },
  metaInfo() {
    return {
      title: '天玑云-' + this.$route.query.name,
      meta: [{
        name: "description",
        content: "天玑云，天玑云获客学院，提供一站式全链路获取客户的解决方案，" + this.$route.query.name
      }, {
        name: "keywords",
        content: "天玑云 " + this.$route.query.name
      }]
    };
  }
});