/*
 * @Author       : code13
 * @Date         : 2021-03-01 13:57:37
 * @LastEditTime : 2021-03-01 13:57:37
 * @LastEditors  : code13
 * @Description  : Request 请求封装
 * @FilePath     : \src\api\request.ts
 * @https://github.com/Code-13
 */
import service from "./axios";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export interface Result<T> {
  count?: number;
  code: number;
  msg: string;
  data: T;
  t?: number;
}

const getRequest = async <T = any, P = any>(
  url: string,
  params: P = {} as P
): Promise<Result<T>> => {
  return service
    .get(url, { params })
    .then((response) => {
      return response.data as Result<T>;
    })
    .catch((_: AxiosResponse): any => void 0);
};

const postRequest = async <T = any, D = any>(
  url: string,
  data: D = {} as D,
  config: AxiosRequestConfig = {}
): Promise<Result<T>> => {
  return service
    .post(url, data, config)
    .then((response) => {
      return response.data as Result<T>;
    })
    .catch((_: AxiosResponse): any => void 0);
};

const putRequest = async <T = any, D = any>(
  url: string,
  data: D = {} as D
): Promise<Result<T>> => {
  return service
    .put(url, data)
    .then((response) => {
      return response.data as Result<T>;
    })
    .catch((_: AxiosResponse): any => void 0);
};

const deleteRequest = async <T = any, D = any>(
  url: string,
  data: D = {} as D
): Promise<Result<T>> => {
  return service
    .delete(url, data)
    .then((response) => {
      return response.data as Result<T>;
    })
    .catch((_: AxiosResponse): any => void 0);
};

export const request = {
  get: getRequest,
  post: postRequest,
  put: putRequest,
  delete: deleteRequest,
};

export default request;
