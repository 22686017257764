import axios from "axios";

const service = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL,
});

service.interceptors.request.use(undefined, (error) => {
  return Promise.reject(error);
});

export default service;
