import { defineComponent, ref } from "vue";
import RecommendArticle from "@/views/college/components/RecommendArticle.vue";
import SpecialTopics from "@/views/college/components/SpecialTopics.vue";
import NewestArticle from "@/views/college/components/NewestArticle.vue";
import Tag from "@/views/college/components/Tag.vue";
export default defineComponent({
  name: 'zixun',
  components: {
    RecommendArticle,
    SpecialTopics,
    NewestArticle,
    Tag
  },
  metaInfo() {
    return {
      title: '天玑云-天玑云获客学院',
      meta: [{
        name: "description",
        content: "天玑云，天玑云获客学院，提供一站式全链路获取客户的解决方案"
      }, {
        name: "keywords",
        content: "天玑云 获客 资讯 " + this.tags
      }]
    };
  },
  setup: () => {
    const tags = ref('');
    const getTags = val => {
      tags.value = val.slice(0, 19).map(item => {
        return item.name;
      }).join(' ');
    };
    return {
      tags,
      getTags
    };
  }
});