import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Case from "../views/Case.vue";
import About from "../views/About.vue";
import Matrix from "../views/Matrix.vue";
import Marketing from "../views/Marketing.vue";
import ZiXun from "../views/college/index.vue";
import ZiXunDetail from "../views/college/detail.vue";
import Special from "../views/college/specialTopicDetail.vue";
import WorkWx from "../views/workWx/index.vue";
import { isMobile } from "@/utils/isMobile";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },

  {
    path: "/case",
    name: "case",
    component: Case,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/matrix",
    name: "matrix",
    component: Matrix,
  },
  {
    path: "/marketing",
    name: "marketing",
    component: Marketing,
  },
  {
    path: "/zixun",
    name: "zixun",
    component: ZiXun,
  },
  {
    path: "/zixun/:id",
    name: "zixunDetail",
    component: ZiXunDetail,
  },
  {
    path: "/zixun/zhuanti/:topicId",
    name: "zhuanti",
    component: Special,
  },
  {
    path: "/zixun/biaoqian/:tagId",
    name: "biaoqian",
    component: Special,
  },
  {
    path: "/workWx",
    name: "workWx",
    component: WorkWx,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => globalThis.scrollTo(0, 0),
});

router.beforeEach((to, from, next) => {
  if (isMobile() && ['zixun', 'zixunDetail', 'zhuanti', 'biaoqian'].includes(to.name as string)) {
    next({
      name: 'home'
    })
  }
  next(true)
})

export default router;
