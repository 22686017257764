import { setBlockTracking as _setBlockTracking, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-013c9118"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "padding-16"
};
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = {
  class: "right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TopicDetail = _resolveComponent("TopicDetail");
  const _component_RecommendArticle = _resolveComponent("RecommendArticle");
  const _component_Tag = _resolveComponent("Tag");
  return _cache[0] || (_setBlockTracking(-1), _cache[0] = _createElementVNode("div", {
    class: "container"
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_TopicDetail, {
    onGetInfo: _ctx.getInfo
  }, null, 8, ["onGetInfo"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_RecommendArticle), _createVNode(_component_Tag)])])]), _setBlockTracking(1), _cache[0]);
}