import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8b9c9722"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  class: "padding-16"
};
const _hoisted_3 = {
  class: "left"
};
const _hoisted_4 = {
  class: "right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SpecialTopics = _resolveComponent("SpecialTopics");
  const _component_NewestArticle = _resolveComponent("NewestArticle");
  const _component_RecommendArticle = _resolveComponent("RecommendArticle");
  const _component_Tag = _resolveComponent("Tag");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_SpecialTopics), _createVNode(_component_NewestArticle)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_RecommendArticle), _createVNode(_component_Tag, {
    onGetTags: _ctx.getTags
  }, null, 8, ["onGetTags"])])])]);
}